import React from "react";
import FAQ from "../components/FAQ";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function About({data}) {
    return (
        <>
            <Page title={"Om oss"}>
                <div className="py-12 bg-white">
                    <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
                        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                            <div className="md:7/12 lg:w-6/12">
                                <h1 className="text-2xl text-gray-900 font-bold md:text-4xl">Om oss</h1>
                                <p className="mt-6 text-gray-600">Idag finns det mängder med alternativ på den svenska elmarknaden och vi ansåg att det inte riktigt fanns någon bra jämförelsetjänst för de över 100 olika elhandelsbolag i Sverige. Detta var ett problem som vi vill lösa med vår tjänst.</p>
                                <p className="mt-4 text-gray-600">Elavtalen.se är tjänsten för dig som är på jakt efter det bästa och billigaste elavtalet på marknaden. Vi jämför de olika alternativen för dig för att du ska ha det enklare att ta ett bättre beslut.</p>

                            </div>
                            <div className="md:5/12 lg:w-5/12">
                            <GatsbyImage image={data.homeTop.childImageSharp.gatsbyImageData} className={"rounded-2xl"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container  px-5 mx-auto ">
                    <div>
                        <section className="text-gray-700">
                            <div className="container px-5 py-16 mx-auto">
                                <div className="text-center mb-20">
                                    <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                                        Vanliga frågor
                                    </h1>
                                    <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                                        Nedan listar vi några av de vanligaste frågorna som vi brukar få.
                                    </p>
                                </div>
                                <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                                    <div className="w-full lg:w-1/2 px-4 py-1">
                                        <FAQ question={"Varför finns inte alla elavtal med?"} content={"Idag finns det över 100 elavtal på marknaden och vi försöker ständigt bygga ut vår tjänst för att täcka alla alternativen."} />
                                        <FAQ question={"Kan ni hjälpa mig välja elavtal?"} content={"Elavtalen.se är endast en informationstjänst och vi bidrar därför inte med någon rådgivning."} />
                                        <FAQ question={"När ska man teckna elavtal?"} content={"Om du ska flytta är det bra att teckna elavtal i så god tid som möjligt, men helst två veckor innan är en bra rekommendation."} />
                                    </div>
                                    <div className="w-full lg:w-1/2 px-4 py-1">
                                        <FAQ question={"Vad är skillnaden mellan elnät och elhandel?"} content={"Elnät är ledningarna som går hem till dig medan elhandeln är den el som du förbrukar och köper av ett elhandelsbolag."} />
                                        <FAQ question={"Vad händer om jag inte tecknat elavtal?"} content={"Om du inte tecknar något särskilt elhandelsavtal, kommer du ändå att få el till ditt hem. Det sker genom att din nätägare anvisar dig en elhandlare. En nackdel är att detta kan bli dyrare."} />
                                        <FAQ question={"När börjar ett elavtal gälla?"} content={"Tiden som det tar att få igång elen beror på vilket elnätsbolag som äger elnätet och vad de har för regler. Försök därför att vara ute i god tid."} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Page>
        </>
    );
}


export const pageQuery = graphql`
  query {
    homeTop: file(relativePath: { eq: "co-living.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
